.expandable-container {
    margin-bottom: 15px;
    border: 0px solid #ddd;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
}

.expandable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.expandable-icon {
    color: #19d0ef;
    margin-right: 10px;
}

.arrow {
    transition: transform 0.6s ease;
    color: #19d0ef;
    font-size: 22px;
    margin-right: 15px;
}

.arrow.open {
    transform: rotate(270deg);
}

.expandable-button {
    padding: 10px 20px;
    border: none;
    background-color: #000030;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.expandable-button:hover {
    background-color: #000030;
}

.tab-buttons-client {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.button-number-wrapper {
    display: flex;
    width: 90%;
}

.tab-button-client {
    text-align: left;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    flex: 0 0 45%;
    color: #000050;
    font-weight: 700;
    text-align: center;
}

.arrow-up {
    margin-right: 10px;
    color: green;
}

.arrow-down {
    margin-right: 10px;
    color: red;
}

.new-note-icon {
    margin-left: 15px;
    cursor: pointer;
    color: #6a6a6a;
    font-size: 20px;
    background-color: transparent;
    border: none;
    padding: 0;
}

.new-note-icon:hover {
    color: #19d0ef;
}

.new-note-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    width: 63%;
}

.new-note-input {
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 5px;
    color: black;
}

#kundenzufriedenheit {
    padding: 8px !important;
}

.new-note-save {
    background-color: #19cfef5b;
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 50%;
    border: None;
    color: #000050;
    font-weight: 600;
    font-size: 14px;
}

.new-note-save:hover {
    background-color: #19d0ef;
}

.number-div {
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px;
    padding-left: 0;
    font-size: 13px;
    font-weight: 600;
}

.tab-content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    max-height: 0;
    opacity: 0;
}

.tab-content.open {
    max-height: 20000px;
    opacity: 1;
}

.summary-data {
    margin-top: 20px;
}

.show-text-icon {
    color: #6a6a6a;
    height: 30px;
    font-size: 20px;
    vertical-align: top;
    border: none;
    background-color: transparent;
}

.show-text-icon:hover {
    color: #19d0ef;
}

.refresh-content {
    color: #6a6a6a;
    height: 30px;
    font-size: 20px;
    vertical-align: top;
    border: none;
    background-color: transparent;
}

.refresh-content:hover {
    color: #19d0ef;
}

ul {
    list-style-position: outside;
    margin: 0px;
    padding: 0px;    
    padding-left: 15px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
.loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #19d0ef;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.2s linear infinite;
}

.speech-loading-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
}

.speech-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #19d0ef;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1.4s linear infinite;
}

.speech-loader-text {
    margin-left: 10px;
    color: #19d0ef;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .expandables-parent {
        width: 95%;
    }
    
    .expandable-container {
        margin-bottom: 15px;
        border: none;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        padding: 10px;
        padding-right: 0px;
        width: 100%;
        box-sizing: border-box;
    }

    .show-text-icon {
        height: 40px;
        font-size: 22px;
        margin: 0px;
        padding: 0px;
        margin-left: 15px;
    }

    .refresh-content {
        height: 40px;
        font-size: 22px;
        margin: 0px;
        padding: 0px;
        margin-left: 15px;
    }

    .new-note-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 15px;
        margin-top: 10px;
        width: 90%;
    }
}