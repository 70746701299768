.expandables-parent {
    width: 800px;
}

.expandable-container {
    margin-bottom: 15px;
    border: 0px solid #ddd;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
}

.expandable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.expandable-icon {
    color: #19d0ef;
    margin-right: 10px;
}

.arrow {
    transition: transform 0.6s ease;
    color: #19d0ef;
    font-size: 22px;
    margin-right: 15px;
}

.arrow.open {
    transform: rotate(270deg);
}

.expandable-button {
    padding: 10px 20px;
    border: none;
    background-color: #000030;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.expandable-button:hover {
    background-color: #000030;
}

.tab-buttons {
    margin-top: 10px;
    border-bottom: 1px solid #d6d6d6;
}

.tab-button {
    padding: 5px 20px;
    border: none;
    margin-right: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 13px;
    color: #000050;
    font-weight: 500;    
}

.tab-button:hover {
    background-color: #19cfef5b;
}

.tab-button.active {
    background-color: #19d0ef;
}

.tab-content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    max-height: 0;
    opacity: 0;
}

.tab-content.open {
    max-height: 1500px;
    opacity: 1;
}

.summary-data {
    margin-top: 20px;
}

.audio-playbar {
    width: 60%; 
    height: 30px;
    margin-right: 0px;
}

.show-text-icon {
    color: #6a6a6a;
    height: 30px;
    font-size: 20px;
    vertical-align: top;
    border: none;
    background-color: transparent;
}

.show-text-icon:hover {
    color: #19d0ef;
}

.refresh-content {
    color: #6a6a6a;
    height: 30px;
    font-size: 20px;
    vertical-align: top;
    border: none;
    background-color: transparent;
}

.refresh-content:hover {
    color: #19d0ef;
}

.data-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    white-space: pre-wrap;
    line-height: 1.3;
    margin-bottom: 5px;
    font-size: 14px;
    color: #4a4a4a;
}

.data-content-visible {
    max-height: 1500px;
}

.data-content h1 {
    color: #2a2a2a;
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
}

.data-content h2 {
    color: #2a2a2a;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    padding: 0px;
}

.data-content h3 {
    color: #2a2a2a;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
}
  
.data-content p {
    font-size: 14px;
    line-height: 1.3;
    color: #4a4a4a;
    margin: 0px;
    padding: 0px;
}

/* Loader */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
.loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #19d0ef;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.2s linear infinite;
}

.speech-loading-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
}

.speech-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #19d0ef;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1.4s linear infinite;
}

.speech-loader-text {
    margin-left: 10px;
    color: #19d0ef;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .expandables-parent {
        width: 95%;
    }
    
    .expandable-container {
        margin-bottom: 15px;
        border: none;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        padding: 10px;
        padding-right: 0px;
        width: 100%;
        box-sizing: border-box;
    }

    .audio-playbar {
        width: 70%; 
        height: 40px;
    }

    .show-text-icon {
        height: 40px;
        font-size: 22px;
        margin: 0px;
        padding: 0px;
        margin-left: 15px;
    }

    .refresh-content {
        height: 40px;
        font-size: 22px;
        margin: 0px;
        padding: 0px;
        margin-left: 15px;
    }
}