.hover-link {
    position: relative;
    text-decoration: underline;
    color: inherit;
}

.hover-link::before {
    content: "";
    visibility: hidden;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    width: 175px;
    height: 40px;
    background-image: url('../public/theScreenerLogo.svg');
    background-size: calc(100% - 15px) calc(100% - 15px);
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(2, 0, 63, 0.5);
    background-color: white;
    padding: 10px;
}


.hover-link:hover::before {
    visibility: visible;
}

@media (max-width: 768px) {
    .hover-link:hover::before {
        visibility: hidden;
    }
}