.modal-parent {
  max-height: 80vh;
  overflow: hidden;
  width: 80%;
  max-width: 800px;
  margin: auto;
  border-radius: 8px;
}

.breaches-modal-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.breaches-modal-close-btn {
  background: none;
  border: none;
  font-size: 26px;
  cursor: pointer;
  color: #1976d2;
  padding: 0;
  margin: 0;
}

.breaches-modal-close-btn:hover {
  color: #0a3866;
}

.tabs-parent {
  display: flex;
  overflow-x: auto;
  justify-content: left;
}

.table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.table-container {
  max-height: calc(80vh - 150px);
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 20px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.responsive-table thead {
  background-color: #f4f4f4;
}

.responsive-table th,
.responsive-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
}

.responsive-table th {
  font-weight: bold;
}

.responsive-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.responsive-table tr:hover {
  background-color: #f1f1f1;
}

.responsive-table td {
  word-wrap: break-word;
}

@media (max-width: 600px) {
  .responsive-table th,
  .responsive-table td {
    font-size: 12px;
    padding: 6px;
  }

  .responsive-table th {
    text-align: center;
  }
}
