/* Modal Overlay */
.modal-overlay-frank {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000000000000000;
}

.modal-content-frank {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content-frank h2 {
    margin-bottom: 10px;
}

.modal-content-frank ul {
    padding-left: 20px;
    margin-bottom: 10px;
}

.modal-close-btn-frank {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 26px;
    cursor: pointer;
}

.modal-close-btn-frank:hover {
    color: #19d0ef;
}

.help-list-frank {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0;
}

.help-item-frank {
    display: flex;
    align-items: center;
}

.help-icon-frank {
    font-size: 20px;
    color: #19d0ef;
    margin-right: 10px;
}

h3 {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
}

ul {
    margin-left: 30px;
    margin-bottom: 20px;
    list-style-type: disc;
}

ul li {
    margin-bottom: 5px;
}

.chat-window-frank, .chat-window-frank * {
    font-family: Arial, sans-serif;
}

.chat-widget-icon-frank {
    position: fixed;
    bottom: 25px;
    right: 25px;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 28px;
    z-index: 999998 !important;
    animation: pulse-frank 2s infinite;
}

@keyframes pulse-frank {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.chat-title-frank {
    font-size: 18px;
}

.chat-window-frank {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 10000000 !important;
    opacity: 1;
    transform: translateY(100%);
    /* transition: opacity 0.8s ease, transform 0.8s ease, width 0.8s ease, height 0.8s ease, margin-left 0.8s ease; */
}

/* Large Chat Window */
.large-chat {
    left: 50%;
    margin-left: -400px;
    width: 800px;
    top: 105px;
}

/* Small Chat Window */
.small-chat {
    margin-left: 0;
    width: 450px;
    height: 600px;
}

.chat-window-frank.show-frank {
    opacity: 1;
    transform: translateY(0);
}

.hidden-frank {
    display: none;
}

.chat-header-frank {
    color: white;
    padding: 15px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
}

.chat-header-title-frank {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-title-frank {
    font-weight: 600;
    font-size: 22px;
}

.close-word-container-frank {
    display: flex;
    align-items: center;
    gap: 20px;
}

.close-btn-frank {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin: 0;
    padding: 0;
    outline: none;
}

.close-chat-widget-icon-frank {
    font-size: 24px;
}

.close-chat-widget-icon-frank:hover {
    color: #000030;
}

.export-word-icon-frank {
    font-size: 22px;
}

.export-word-icon-frank:hover {
    color: #000030;
}

.question-icon-frank {
    font-size: 22px;
}

.question-icon-frank:hover {
    color: #000030;
}

.resize-chat-frank {
    font-size: 22px;
}

.resize-chat-frank:hover {
    color: #000030;
}

.chat-body-frank {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.chat-footer-frank {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    gap: 10px;
}

.powered-by-frank {
    display: flex;
    justify-content: center;
}

.powered-by-text-frank {
    font-size: 10px;
    color: rgb(146, 146, 146);
    cursor: pointer;
    text-decoration: underline;
}

.input-send-container-frank {
    display: flex;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.chat-footer-frank input {
    flex: 1;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
}

.chat-footer-frank button {
    margin-left: 10px;
    padding: 5px 10px;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #19d0ef;
}

.chat-footer-frank button:hover {
    background-color: #18a6bf;
}

.chat-footer-frank input.disabled-frank {
    background-color: #f1f1f1;
    cursor: not-allowed;
    color: #535353;
}

.chat-footer-frank button.disabled-frank {
    background-color: #f1f1f1;
    cursor: not-allowed;
    color: #535353;
}

.chat-footer-frank button.disabled-frank:hover {
    background-color: #f1f1f1;
}

.widget-message-frank {
    max-width: 70%;
    margin: 0;
    padding: 5px;
    border-radius: 10px;
    display: inline-block;
}

.widget-message-frank.custom-max-width {
    max-width: 90%;
}

.widget-user-message-frank {
    align-self: flex-end;
    color: white;
    border-radius: 8px;
    padding: 8px;
    font-size: 16px;
}

.widget-user-message-frank > :first-child {
    padding: 0px;
    margin: 0px;
}

.widget-user-message-frank > :last-child {
    padding: 0px;
    margin: 0px;
}

.widget-bot-message-frank {
    align-self: flex-start;
    background-color: #ffffff;
    color: rgb(31, 31, 31);
    border-radius: 8px;
    font-size: 16px;
}

.widget-bot-message-frank p {
    margin-block-start: 0.7em;
    margin-block-end: 0.7em;
}

.widget-bot-message-frank > :first-child {
    padding-top: 0px;
    margin-top: 0px;
}

.widget-bot-message-frank > :last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.widget-send-icon-frank {
    font-size: 16px;
    font-style: normal;
}

/* table styles */
.widget-message-frank table {
    border-collapse: separate;
    border-spacing: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    overflow: auto;
}

.widget-message-frank th, .widget-message-frank td {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: .35rem .50rem;
    text-align: left;
    font-size: 14px;
}

.widget-message-frank th {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.1);
    border-right: 0;
    padding: .5rem .50rem;
}

.widget-message-frank th:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.widget-message-frank th {
    border-bottom: none;
}

.widget-message-frank td {
    border-bottom: 0px solid rgba(0, 0, 0, 0.15);
}

.widget-message-frank td {
    border-right: 0px solid rgba(0, 0, 0, 0.15);
}

.widget-message-frank td:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.widget-message-frank tr:first-child th:first-child {
    border-top-left-radius: 10px; /* Rounded top left corner */
}
.widget-message-frank tr:first-child th:last-child {
    border-top-right-radius: 10px; /* Rounded top right corner */
}
.widget-message-frank tr:last-child td:first-child {
    border-bottom-left-radius: 10px; /* Rounded bottom left corner */
}
.widget-message-frank tr:last-child td:last-child {
    border-bottom-right-radius: 10px; /* Rounded bottom right corner */
}

.widget-message-frank tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); /* Adds bottom border back on all cells of the last row */
}
/*Table style ending*/

/* Loading dots */
.loading-dots-frank {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.loading-dots-frank div {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 50%;
    animation: loading 0.6s infinite alternate;
    background-color: #19d0ef;
}

@keyframes loading {
to {
    opacity: 0.3;
    transform: translateY(-8px);
}
}

.loading-dots-frank div:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots-frank div:nth-child(3) {
    animation-delay: 0.4s;
}

@media (max-width: 768px) {
    .chat-overlay-frank {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        z-index: 999999;
    }

    .chat-window-frank {
        position: fixed;
        left: 4%;
        bottom: 1%;
        width: 92%;
        height: 90%;
        border-radius: 16px;
    }

    .large-chat {
        margin-left: 0;
        top: auto;
    }

    .resize-chat-frank {
        display: none;
    }

    body.no-scroll-frank {
        overflow: hidden;
    }

    .close-word-container-frank {
        gap: 18px;
    }

    .chat-header-frank {
        padding: 15px;
        padding-right: 12px;
    }

    .modal-content-frank {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        width: 83%;
        position: relative;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
}
