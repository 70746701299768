.login-logo {
    width: 120px;
    margin-top: 40px;
    margin-bottom: 0px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    margin: 0 auto;
    width: 100%;
}

.login-form h1 {
    color: #19d0ef;
    font-size: 40px;
    margin-bottom: 30px;
}

.login-input input {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    background-color: white;
    margin-bottom: 15px;
    width: 300px;
}

.passwort-forgotten {
    color: white;
    font-size: 14px;
    margin-bottom: 25px;
}

.passwort-forgotten:hover {
    color: #19d0ef;
    cursor: pointer;
}

.new-member {
    margin-top: 40px;
    text-align: center;
    color: white;
    font-size: 14px;
}

.new-member:hover {
    color: #19d0ef;
    cursor: pointer;
}

.login-input input:focus {
    outline: none;
}

.submit-area {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

.submit-login {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    width: 100%;
    background-color: #19d0ef;
    border: none;
    color: #000060;
    font-size: 16px;
    height: 40px;
}

.submit-login:hover {
    background-color: #00accb;
}

.login-loader {
    border: 2px solid #ffffff;
    border-top: 2px solid #042e4a; 
    border-radius: 50%;
    width: 14px;
    height: 14px;
    animation: spin 1.5s linear infinite;
    margin-left: 7px;
    display: inline-block;
    vertical-align: middle;
  }

.login-error {
    color: rgb(216, 0, 0);
    margin-top: 15px;
    font-size: 15px;
}

@media (max-width: 768px) {
    .login-logo {
        margin-top: 20px;
    }
}