body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000000000000000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-bottom: 10px;
}

.modal-content ul {
    padding-left: 20px;
    margin-bottom: 10px;
}

.modal-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 26px;
    cursor: pointer;
}

.modal-close-btn:hover {
    color: #19d0ef;
}

.help-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0;
}

.help-item {
    display: flex;
    align-items: center;
}

.help-icon {
    font-size: 20px;
    color: #19d0ef;
    margin-right: 10px;
}

h3 {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
}

ul {
    margin-left: 30px;
    margin-bottom: 20px;
    list-style-type: disc;
}

ul li {
    margin-bottom: 5px;
}

.chat-window, .chat-window * {
    font-family: Arial, sans-serif;
}

.chat-widget-icon {
    position: fixed;
    bottom: 25px;
    right: 25px;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 28px;
    z-index: 999998 !important;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.chat-title {
    font-size: 18px;
}

.chat-window {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 450px;
    height: 600px;
    background-color: transparent;
    border: none;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 10000000 !important;
    opacity: 1;
    transform: translateY(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.chat-window.show {
    opacity: 1;
    transform: translateY(0);
}

.hidden {
    display: none;
}

.chat-header {
    color: white;
    padding: 15px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    flex-direction: column;
}

.chat-header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-title {
    font-weight: 600;
    font-size: 22px;
}

.close-word-container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.close-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin: 0;
    padding: 0;
    outline: none;
}

.close-chat-widget-icon {
    font-size: 24px;
}

.close-chat-widget-icon:hover {
    color: #000030;
}

.export-word-icon {
    font-size: 22px;
}

.export-word-icon:hover {
    color: #000030;
}

.question-icon {
    font-size: 22px;
}

.question-icon:hover {
    color: #000030;
}

.chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.chat-footer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    gap: 10px;
}

.powered-by {
    display: flex;
    justify-content: center;
}

.powered-by-text {
    font-size: 10px;
    color: rgb(146, 146, 146);
    cursor: pointer;
    text-decoration: underline;
}

.input-send-container {
    display: flex;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.chat-footer input {
    flex: 1;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
}

.chat-footer button {
    margin-left: 10px;
    padding: 5px 10px;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.chat-footer button:hover {
    background-color: #19d0ef;
}

.chat-footer input.disabled {
    background-color: #f1f1f1;
    cursor: not-allowed;
    color: #535353;
}

.chat-footer button.disabled {
    background-color: #f1f1f1;
    cursor: not-allowed;
    color: #535353;
}

.chat-footer button.disabled:hover {
    background-color: #f1f1f1;
}

.widget-message {
    max-width: 80%;
    margin: 5px 0;
    padding: 5px;
    border-radius: 10px;
    display: inline-block;
}

.widget-user-message {
    align-self: flex-end;
    color: white;
    border-radius: 8px;
    padding: 8px;
    font-size: 16px;
}

.widget-user-message > :first-child {
    padding: 0px;
    margin: 0px;
}

.widget-user-message > :last-child {
    padding: 0px;
    margin: 0px;
}

.widget-bot-message {
    align-self: flex-start;
    background-color: #ffffff;
    color: rgb(31, 31, 31);
    border-radius: 8px;
    font-size: 16px;
}

.widget-bot-message > :first-child {
    padding-top: 0px;
    margin-top: 0px;
}

.widget-bot-message > :last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.widget-send-icon {
    font-size: 16px;
    font-style: normal;
}

/* table styles */
.widget-message table {
    border-collapse: separate;
    border-spacing: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    overflow: auto;
}

.widget-message th, .widget-message td {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: .35rem .50rem;
    text-align: left;
    font-size: 14px;
}

.widget-message th {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.1);
    border-right: 0;
    padding: .5rem .50rem;
}

.widget-message th:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.widget-message th {
    border-bottom: none;
}

.widget-message td {
    border-bottom: 0px solid rgba(0, 0, 0, 0.15);
}

.widget-message td {
    border-right: 0px solid rgba(0, 0, 0, 0.15);
}

.widget-message td:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.widget-message tr:first-child th:first-child {
    border-top-left-radius: 10px; /* Rounded top left corner */
}
.widget-message tr:first-child th:last-child {
    border-top-right-radius: 10px; /* Rounded top right corner */
}
.widget-message tr:last-child td:first-child {
    border-bottom-left-radius: 10px; /* Rounded bottom left corner */
}
.widget-message tr:last-child td:last-child {
    border-bottom-right-radius: 10px; /* Rounded bottom right corner */
}

.widget-message tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); /* Adds bottom border back on all cells of the last row */
}
/*Table style ending*/

/* Loading dots */
.loading-dots {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.loading-dots div {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 50%;
    animation: loading 0.6s infinite alternate;
}

/* Add this to your CSS file */
.input-with-loader {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.chat-input {
    width: 100%;
    height: 100%;
    padding-left: 32px;
    box-sizing: border-box;
}

.audio-loader {
    position: absolute;
    left: 8px;
    z-index: 1;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #0082ba;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.6s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
@keyframes loading {
to {
    opacity: 0.3;
    transform: translateY(-8px);
}
}

.loading-dots div:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots div:nth-child(3) {
    animation-delay: 0.4s;
}

.record {
    align-items: center;
}

.widget-record-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    display: inline-block;
    line-height: 20px;
}

.widget-record-icon svg {
    width: 100%;
    height: 100%;
}

.widget-record-icon .fa-circle {
    color: red;
}

@media (max-width: 768px) {
    .chat-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        z-index: 999999;
    }

    .chat-window {
        position: fixed;
        left: 4%;
        bottom: 1%;
        width: 92%;
        height: 90%;
        border-radius: 16px;
    }

    body.no-scroll {
        overflow: hidden;
    }

    .close-word-container {
        gap: 18px;
    }

    .chat-header {
        padding: 15px;
        padding-right: 25px;
    }

    .modal-content {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        width: 83%;
        position: relative;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .widget-record-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
        display: inline-block;
        line-height: 18px;
    }
}
